<template>
  <div>
    <v-tabs v-model="tab" background-color="rgb(246, 246, 250)">
      <v-tab>图文信息</v-tab>
      <v-tab>销售规格</v-tab>
      <v-tab-item>
        <graphic-info
            :code="item.groupid"
            :p_form="item"
            :edit="true"
            @change="form2 = $event"
        />
      </v-tab-item>
      <v-tab-item>
        <sales-specification
            :edit="true"
            :code="item.groupid"
            @change="form3 = $event"
            :p_form="item"/>
      </v-tab-item>
    </v-tabs>
    <v-footer
        style="background: #ffffff; padding: 20px 24px"
        padless
        absolute
    >
      <v-btn @click="reset" depressed outlined color="primary">重置</v-btn>
      <v-btn class="ml-4" @click="save" depressed color="primary" :loading="loading">保存</v-btn>
    </v-footer>
  </div>
</template>

<script>
import graphicInfo from "./edit-product-components/graphic-info.vue";
import salesSpecification from "./edit-product-components/sales-specification.vue";

export default {
  name: "edit-product",
  components: {
    graphicInfo,
    salesSpecification
  },
  data() {
    return {
      tab: null,
      o_item: {},
      item: {},
      form2: {},
      form3: {},
      loading: false
    }
  },
  mounted() {
    this.axios.post('/api/gift/load_giftitem', {sqlid: this.$route.query.sqlid}).then(res => {
      this.item = res.data
      this.o_item = res.data
    })
  },
  methods: {
    save() {
      let params = Object.assign(this.item, this.form2, this.form3)
      this.axios.post('/api/gift/save_giftitem', params).then(res => {
        if (res.code === this.staticVal.Code.Success) {
          this.snackbar.success('商品修改成功')
          this.$router.push('/product-list')
        }
      })
    },
    reset() {
      this.item = this.o_item
    }
  }
}
</script>

<style scoped lang="scss">

.v-stepper__header, .v-sheet.v-stepper:not(.v-sheet--outlined) {
  box-shadow: none;
}

::v-deep {
  .v-tabs-items {
    background-color: rgb(246, 246, 250);
  }
}
</style>
